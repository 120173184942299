import Image from "next/image";

/* eslint react/no-unescaped-entities: 0 */

type LocalizedTextLeaf = { no: string | JSX.Element; en: string | JSX.Element };

type LocalizedText = {
  [key: string]: LocalizedText | LocalizedTextLeaf;
};

export const localizedText = {
  common: {
    buttons: {
      ok: {
        en: "OK",
        no: "OK",
      },
      confirm: {
        en: "Confirm",
        no: "Bekreft",
      },
      cancel: {
        en: "Back",
        no: "Avbryt",
      },
      save: {
        en: "Save",
        no: "Lagre",
      },
      transfer: {
        en: "Transfer now",
        no: "Overfør nå",
      },
      pay: {
        en: "Pay now",
        no: "Betal nå",
      },
    },
  },
  changeLanguage: {
    no: "Språk: ",
    en: "Language: ",
  },
  loans: {
    title: {
      no: "Mine Lån",
      en: "My Loans",
    },
    empty: {
      title: { no: "Lån med Vanir", en: "Loan with Vanir" },
      text: {
        no: "Du kan opprette et nytt lån, men du kan ikke starte det før du har nok valuta stående som sikkerhet på konto.",
        en: "You can open a new loan, but not before you have enough balance available as collateral on your account.",
      },
      button: {
        no: "Opprett nytt lån",
        en: "Create new loan",
      },
    },
  },
  activeTransactionView: {
    infoText: {
      open: { no: "Lånet ditt settes opp", en: "Your loan is being created" },
      closed: { no: "Lånet ditt betales ned", en: "Your loan is being paid" },
      default: { no: "Endrer lånet ditt", en: "Changing your loan" },
    },
    spinnerText: {
      no: " - venter på bekreftelse ",
      en: " - waiting for confirmation ",
    },
    seeTransaction: {
      no: " se transaksjonen",
      en: " see transaction",
    },
  },
  loanView: {
    buttons: {
      payLoan: {
        no: "BETAL LÅN",
        en: "PAY LOAN",
      },
      addToLoan: {
        no: "ØK LÅN",
        en: "INCREASE LOAN",
      },
      changeCollateral: {
        no: "ENDRE SIKKERHET",
        en: "ADJUST COLLATERAL",
      },
    },
    warningLabel: {
      no: "Du må sette inn mer sikkerhet eller betale ned litt av lånet - litt om hva grunnen til det er og hva som kan skje.",
      en: "Increase your collateral or pay off a portion of the loan",
    },
    helpText: {
      interestRate: {
        no: "Rentesats ",
        en: "Interest rate ",
      },
      loanFactor: {
        no: "Lånegrad ",
        en: "LTV ",
      },
      stable: {
        no: "Stabil ",
        en: "Stable ",
      },
      unstable: {
        no: "Ustabil ",
        en: "Unstable ",
      },
      ofMax: {
        no: " av maks ",
        en: " of max ",
      },
      totalLoan: {
        no: "Totalt lån",
        en: "Total loan",
      },
      availableLoan: {
        no: "Tilgjenelig ekstra lån ",
        en: "Available extra loan ",
      },
      collateral: {
        no: "Sikkerhet ",
        en: "Collateral ",
      },
    },
  },
  loanModal: {
    title: {
      en: "Confirm Loan",
      no: "Bekreft Lån",
    },
    subtitle: {
      NOKtoAcc: {
        en: "Once you confirm this loan, Vanir will facilitate the conversion of your DAI into NOK through the K33 Exchange. You need not take any further action; the money will be in your bank account on the following workday",
        no: "Når du bekrefter dette lånet, vil Vanir legge til rette for konverteringen av DAI-en din til NOK gjennom K33-børsen. Du trenger ikke foreta deg noe mer; pengene vil stå på bankkontoen din påfølgende arbeidsdag",
      },
      DAItoWallet: {
        en: "After you confirm the loan your Vanir wallet will be funded with DAI. You have the flexibility to transfer these funds to your personal wallet at your convenience. Remember, for your protection, whitelisting the recipient wallet is a mandatory step before transferring funds",
        no: "Etter at du har bekreftet lånet, vil Vanir-lommeboken din bli finansiert med DAI. Du har fleksibiliteten til å overføre disse midlene til din personlige lommebok når det passer deg. Husk at for din egen sikkerhet er hvitelisting av mottakerlommeboken et obligatorisk trinn før du overfører penger",
      },
    },
    loan: {
      details: {
        en: "Loan Details",
        no: "Lånedetaljer",
      },
      interestRate: {
        en: "Interest Rate",
        no: "Rentesats",
      },
      amount: {
        en: "Loan Amount",
        no: "Lånebeløp ",
      },
      paidOut: {
        en: "Amount Paid Out",
        no: "Beløpet betalt",
      },
      collateral: {
        en: "Collateral",
        no: "Sikkerhet",
      },
      length: {
        en: "Loan Maturity",
        no: "Lånets løpetid",
      },
      maxRate: {
        en: "Max loan-to-value ratio",
        no: "Maks belåningsgrad",
      },
    },
    missing: {
      bankAccount: {
        label: {
          en: "Bank Account Number",
          no: "Bankkonto nummer",
        },
        helpText: {
          en: `It looks like we are missing your account number.
In order for us to be able to pay out the loan, we need a bank account.`,
          no: `Det ser ut som om vi mangler ditt kontonummer.
For at vi skal ha mulighet å betale ut lånet trenger vi et bankkonto.`,
        },
      },
      walletAddress: {
        label: {
          en: "Address for withdrawal of $DAI",
          no: "Adresse for uttak av $DAI",
        },
        helpText: {
          en: "In order for you to be able to withdraw $DAI from your Vanir wallet, the address must be whitelisted",
          no: "For at du skal kunne ta ut $DAI fra din Vanir lommebok må adressen whitelistes",
        },
      },
    },
    serverErrors: {
      en: "Something went wrong. Please try again later.",
      no: "Noe gikk galt. Prøv igjen senere.",
    },
  },
  inactiveLoan: {
    liquidated: {
      no: "Lånet er misligholdt og sikkerheten har blitt likvidert for å betale ned lånet",
      en: "There was a breach in contract and the collateral has been liquidated to pay off the loan",
    },
    totalLoan: {
      no: "Totalt lån",
      en: "Total loan",
    },
    paidOff: {
      no: "Nedbetalt",
      en: "Paid off",
    },
    closedLoan: {
      no: "Gjenstående sikkerhet har blitt tilbakeført til din konto",
      en: "The remaining collateral has been returned to your account",
    },
  },
  openLoan: {
    title: {
      no: "Ønsket lån ",
      en: "Desired loan ",
    },
    ltvText: {
      danger: {
        no: "Veldig ustabil belåningsgrad: ",
        en: "Very unstable loan-to-value ratio: ",
      },
      warning1: {
        no: "Ustabil belåningsgrad: ",
        en: "Unstable loan-to-value ratio: ",
      },
      warning2: {
        no: "Ustabil belåningsgrad: ",
        en: "Unstable loan-to-value ratio: ",
      },
      healthy: {
        no: "Stabil belåningsgrad: ",
        en: "Stable loan-to-value ratio: ",
      },
    },
    loanAmount: {
      label: { no: "Lånebeløp", en: "Loan amount" },
      placeholder: { no: "Lånebeløp", en: "Loan amount" },
    },
    collateral: {
      label: { no: "Sikkerhet (Etherum)", en: "Collateral (Etherum)" },
      placeholder: { no: "Sikkerhet", en: "Collateral" },
    },
    helpText: {
      corresponds: {
        no: "Tilsvarer ",
        en: "Corresponds to ",
      },
      kronerNow: {
        no: " kroner per nåværende kurs ",
        en: " kroner at current rate ",
      },
      ofMax: {
        no: " av maks ",
        en: " of max ",
      },
    },
    mainButton: {
      no: "Start dette lånet nå",
      en: "Start this loan now",
    },
  },
  adapterInfoView: {
    label: {
      no: "Belåningsgrad ",
      en: "Loan-to-value ratio",
    },
    loanName: {
      low: {
        no: "Lav ",
        en: "Low ",
      },
      medium: {
        no: "Medium ",
        en: "Medium ",
      },
      high: {
        no: "Høy ",
        en: "High ",
      },
    },
    text: {
      smallestAmount: {
        no: "Minstebeløp: ",
        en: "Lowest amount: ",
      },
      interest: {
        no: "Rente: ",
        en: "Rate: ",
      },
    },
  },
  vaultAccountView: {
    accounts: {
      no: "Total balanse",
      en: "Total balance",
    },
  },
  walletView: {
    take: {
      no: "Ta ut",
      en: "Withdraw",
    },
    put: {
      no: "Fyll opp",
      en: "Top up",
    },
  },
  deposit: {
    title: {
      no: "Fyll på ",
      en: "Top up ",
    },
    helpText: {
      move: {
        no: "Fyll på konto ved å overføre ",
        en: "Top up account by transferring ",
      },
      to: {
        no: " til",
        en: " to",
      },
    },
    message: {
      no: "Adressen ble kopiert!",
      en: "The address was copied!",
    },
  },

  // Withdraw modal
  withdraw: {
    toggleConversion: {
      no: "Veksle til NOK",
      en: "Exchange to NOK",
    },
    title: {
      no: "Ta ut",
      en: "Withdraw",
    },
    notWhilelistedTitle: {
      no: "Ikke noe whitelisted wallet",
      en: "No wallet address is whitelisted",
    },
    notWhileListedText: {
      firstParagraph: {
        en: "For additional security measures, it's necessary to whitelist a wallet address before transfrring funds from your Vanir wallet.",
        no: "For ytterligere sikkerhetstiltak er det nødvendig å hvitliste en lommebokadresse før du overfører penger fra Vanir-lommeboken din.",
      },
      secondParagraph: {
        en: "Whitelisting can be easily managed in your Vanir User Profile",
        no: "Hvitelisting kan enkelt administreres i din Vanir-brukerprofil",
      },
    },
    subTitle: {
      firstPart: {
        no: "Du har ",
        en: "You have ",
      },
      secondPart: {
        no: " tilgjengelig for uttak",
        en: " available for withdrawal",
      },
    },
    withdrawAmount: {
      label: {
        no: "Beløp du vil ta ut",
        en: "Amount to withdraw",
      },
      placeholder: {
        no: "Beløp",
        en: "Amount",
      },
      tooHigh: {
        no: "Beløpet er for høyt",
        en: "The amount is too high",
      },
    },
    buttons: {
      cancel: {
        no: "Avbryt",
        en: "Cancel",
      },
      withdraw: {
        no: "Ta ut nå",
        en: "Withdraw now",
      },
      userProfile: {
        no: "Brukerprofil",
        en: "User profile",
      },
    },
  },

  changeCollateral: {
    segments: {
      add: {
        no: "Legg til Sikkerhet",
        en: "Deposit Collateral",
      },
      remove: {
        no: "Fjern Sikkerhet",
        en: "Withdraw Collateral",
      },
    },
    title: {
      no: "Endre sikkerhet",
      en: "Adjust collateral",
    },
    helpText: {
      have: {
        no: "Du har ",
        en: "You have ",
      },
      add: {
        no: "tilgjengelig for sikkerhet",
        en: "available for collateral",
      },
      remove: {
        no: "tilgjengelig for uttak",
        en: "avilable for withdrawal",
      },
      newLtv: {
        no: "Ved overføring vil ny belåningsgrad bli ",
        en: "The new loan-to-value ratio after the transfer is ",
      },
    },
    buttons: {
      cancel: {
        no: "Avbryt",
        en: "Cancel",
      },
      transfer: {
        no: "Overfør nå",
        en: "Transfer now",
      },
    },
    textInput: {
      label: {
        no: "Beløp du vil ta ut fra lånet",
        en: "Amount to take from loan",
      },
      placeholder: {
        no: "Beløp",
        en: "Amount",
      },
    },
  },
  repayLoan: {
    title: {
      no: "Betal Lån",
      en: "Pay Loan",
    },
    text: {
      before: {
        no: "Du har",
        en: "You have ",
      },
      after: {
        no: "i utestående beløp",
        en: "in outstanding balance",
      },
    },
    error: {
      no: "Lånet kan ikke være under ",
      en: "The loan cannot fall below ",
    },
    payEntire: {
      no: "Betal hele lånet?",
      en: "Pay down entire loan?",
    },
    helpText: {
      newAmount: {
        no: "Ved nedbetaling vil ny lånebeløp bli ",
        en: "The new loan amount after the transfer is ",
      },
    },
    textInput: {
      label: {
        no: "Beløp du vil betale",
        en: "Amount to pay",
      },
      placeholder: {
        no: "Beløp",

        en: "Amount",
      },
    },
    buttons: {
      cancel: {
        no: "Avbryt",
        en: "Cancel",
      },
      pay: {
        no: "Betal",
        en: "Pay",
      },
    },
  },
  addDebt: {
    title: {
      no: "Ta ut ekstra lån",
      en: "Take out extra loan",
    },
    text: {
      before: {
        no: "Du har",
        en: "You have ",
      },
      after: {
        no: "i Vanir walleten din",
        en: "in your Vanir wallet",
      },
    },
    helpText: {
      newLtv: {
        no: "Ved overføring vil ny belåningsgrad bli ",
        en: "The new loan-to-value ratio after the transfer is ",
      },
    },
    textInput: {
      label: {
        no: "Beløp du vil øke lånet med",
        en: "Amount to add to the loan",
      },
      placeholder: {
        no: "Beløp",
        en: "Amount",
      },
    },
    buttons: {
      cancel: {
        no: "Avbryt",
        en: "Cancel",
      },
      add: {
        no: "Øk nå",
        en: "Add now",
      },
    },
  },
  removeInactiveLoans: {
    no: "Fjern inaktive lån",
    en: "Remove inactive loans",
  },
  logOutButton: {
    no: "Logg ut",
    en: "Log out",
  },
  signInForm: {
    button: {
      no: "Logg på med BankID",
      en: "Sign in with BankID",
    },
  },
  emailVerification: {
    page: {
      message: {
        no: "Eposten din har blitt verifisert.",
        en: "Your email has been verified.",
      },
    },
    banner: {
      no: "Du må verifisere eposten din, vi har sendt deg en epost med en lenke for å gjøre det.",
      en: "You must verify your email address, we have sent you an email with a verification link.",
    },
    link: {
      no: "Send på nytt",
      en: "Resend email",
    },
  },
  smsVerification: {
    page: {
      message: {
        no: "Nummeret ditt har blitt verifisert.",
        en: "Your number has been verified.",
      },
    },
    banner: {
      no: "Du må verifisere nummeret ditt, vi har sendt deg en sms med en lenke for å gjøre det.",
      en: "You must verify your number address, we have sent you a text with a verification link.",
    },
    link: {
      no: "Send på nytt",
      en: "Resend text",
    },
  },
  aboutPage: {
    title: {
      no: "Vanir: En låneplattform for nordmenn",
      en: "Vanir: A loaning platform for Norwegians",
    },
    business: {
      no: "Foretak: Vanir Finance AS",
      en: "Company: Vanir Finance AS",
    },
    contact: {
      no: "Kontakt: Ronny Iversen 45670000",
      en: "Contact: Ronny Iversen 45670000",
    },
  },
  emailResendPage: {
    no: "Eposten er sendt",
    en: "Email sent",
  },
  smsResendPage: {
    no: "Sms er sendt",
    en: "Text sent",
  },
  validationErrors: {
    empty: {
      no: "Felt påkrevd",
      en: "Field is required",
    },
    email: {
      no: "Ugyldig E-Post addresse",
      en: "Invalid email",
    },
    phone: {
      no: "Ugyldig telefonnummer",
      en: "Invalid phone number",
    },
    wallet: {
      no: "Ugyldig addresse",
      en: "Invalid wallet",
    },
    alreasdyInUse: {
      email: {
        no: "Eposten er allerede i bruk",
        en: "Email is already in use",
      },
      phone: {
        no: "Telefonnummeret er allerede i bruk",
        en: "Phone number is already in use",
      },
    },
  },
  userSettings: {
    title: {
      no: "Din bruker",
      en: "Your user",
    },
    formValues: {
      email: {
        label: { no: "E-Post", en: "Email" },
        placeholder: { no: "E-Post", en: "Email" },
      },
      phone: {
        label: { no: "Mobilnummer", en: "Phone number" },
        placeholder: { no: "Mobilnummer", en: "Phone number" },
      },
      wallet: {
        label: { no: "Lommebok adresse for uttak", en: "Wallet address" },
        placeholder: { no: "Lommebok", en: "Wallet" },
      },
      bankAccount: {
        label: { no: "Kontonummer", en: "Account number" },
        placeholder: { no: "Kontonummer", en: "Account number" },
      },
    },
    buttons: {
      cancel: { no: "Avbryt", en: "Cancel" },
      save: { no: "Lagre", en: "Save" },
    },
  },
  idVerification: {
    elements: {
      K33Link: {
        unverified: {
          title: {
            no: "Verifiser identiteten din",
            en: "Verify your identity",
          },
          info: {
            no: "Web browseren åpner en ny fane for verifisering",
            en: "The browser will open a new tab for verification",
          },
        },
        verified: {
          title: {
            no: "Identiteten din er verifisert!",
            en: "Your identity has been verified!",
          },
        },
      },
      userForm: {
        newUser: {
          title: {
            no: "Fyll ut kontaktinformasjon",
            en: "Fill out your contact information",
          },
          info: {
            no: "K33 loans trenger litt informasjon av deg",
            en: "K33 loans needs some details from you",
          },
        },
        currentUser: {
          title: { no: "Kontaktinformasjon", en: "Contact information" },
          info: {
            no: "Oppdater din informasjon",
            en: "Update your information",
          },
        },
        emailHelpText: {
          no: "Verifiser eposten som ble sent ut til din epostadresse",
          en: "Verify the link that was sent to your email",
        },
        smsHelpText: {
          no: "Verifiser SMS som blir sent ut til din telefon",
          en: "Verify the SMS that is sent to your phone",
        },
        walletHelpText: {
          no: "Lommebokadresse for uttak av $DAI (valgfritt)",
          en: "Wallet address for withdrawing $DAI (optional)",
        },
        bankAccountHelpText: {
          no: "Bank kontonummer for uttak av NOK (valgfritt)",
          en: "Bank account number for withdrawal of NOK (optional)",
        },
      },
      validateCommuncation: {
        title: {
          no: "Verifisere SMS og epost",
          en: "Verify SMS and email",
        },
        info: {
          no: "K33 loans bruker SMS og epost til kommunikasjon med deg",
          en: "K33 loans uses SMS and email to communicate with you",
        },
        smsResend: {
          no: "Fikk du ikke SMS? Send på nytt!",
          en: "Didn't receive a text? Send it again!",
        },
        emailResend: {
          no: "Fikk du ikke E-post? Send på nytt!",
          en: "Didn't receive an email? Send it again!",
        },
      },
      verifier: {
        title: {
          no: "K33 loans verifiser opplysningene",
          en: "K33 loans is verifying your details",
        },
        info: {
          no: "Etter verifisering kan du ta opp lån",
          en: "you can take out loans after verification",
        },
        verified: {
          no: "Alle opplysningene er verifisert",
          en: "All details have been verified",
        },
        rejected: {
          no: "Dine opplysningene kunne ikke verifiseres",
          en: "Your details have been rejected",
        },
      },
    },
    signs: {
      email: {
        title: { no: "E-post", en: "Email" },
        unverified: { no: "Ikke verifisert", en: "Unverified" },
        verified: { no: "Verifisert", en: "Verified" },
      },
      sms: {
        title: { no: "SMS", en: "Text" },
        unverified: { no: "Ikke verifisert", en: "Unverified" },
        verified: { no: "Verifisert", en: "Verified" },
      },
    },
  },
  menu: {
    loans: {
      no: "Mine lån",
      en: "My loans",
    },
    newLoan: {
      no: "Nytt lån",
      en: "New loan",
    },
    accounts: {
      no: "Wallet",
      en: "Wallet",
    },
    help: {
      no: "Hjelp & Support",
      en: "Help & Support",
    },
    user: {
      no: "Min profil",
      en: "My profile",
    },
    verify: {
      no: "Verifiser konto",
      en: "Verify account",
    },
  },
  welcomePage: {
    title: {
      line1: {
        no: "Få tilgang til penger",
        en: "Get access to money",
      },
      line2: {
        no: "-du allerede har!",
        en: "-you already have!",
      },
    },
    opening: {
      title: {
        no: "Har du ethereum, men ønsker ikke å selge for å få tilgang til likviditet?",
        en: "Do you have ethereum, but don't wish to sell to get access to liquid assets?",
      },
      paragraph: {
        no: "Med Vanir kan du bruke dine digitale eiendeler som sikkerhet for å ta opp et lån. Dette betyr at du kan få tilgang til pengene du trenger uten å måtte betale skatt på eventuell gevinst eller selge dine eiendeler til en lavere verdi enn du tror de vil ha i fremtiden.",
        en: "With Vanir, you can use your digital assets as collateral for your loan. This means that you can access the money you need without having to pay taxes on eventual gain or sell your assets for a lower price than what you expect to get in the future.",
      },
    },
    middle: {
      1: {
        title: {
          no: "Lån nominert i $DAI (stable coin)",
          en: "Loan given out in $DAI (stable coin)",
        },
        paragraph: {
          no: "Velg mellom å få lånebeløpet utbetalt i DAI eller penger på konto med K33.",
          en: "Choose between withdrawing amounts in DAI or NOK to your K33 account.",
        },
      },
      2: {
        title: {
          no: "Rask tilgang på likviditet",
          en: "Quick access to liquid assets",
        },
        paragraph: {
          no: "Lån penger med dine digitale eiendeler som sikkerhet. Få tilgang til likviditet raskt og enkelt uten lånesøknad.",
          en: "Loan with your digital assets as collateral. Get access to liquid assets fast and easy, without a loan application.",
        },
      },
      3: {
        title: {
          no: "Fleksible lånevilkår",
          en: "Flexible terms",
        },
        paragraph: {
          no: "Fleksible lånevilkår med lav rente, slik at du kan låne det du trenger og betale det tilbake når du selv ønsker",
          en: "Flexible terms with low rent, so you can loan what you need and pay it back when you want.",
        },
      },
    },
    FAQ: {
      title: { no: "Vanlig Stilte Spørsmål", en: "FAQ" },
      QA: {
        importantNote: {
          en: "Important Note: ",
          no: "OBS: ",
        },
        1: {
          question: {
            en: "What is a Digital Asset Loan?",
            no: "What is a Digital Asset Loan?",
          },
          answer: {
            line1: {
              en: `Vanir Digital Assets Loans allows you to borrow against your
crypto assets (known as “Virtual Assets” or “Digital Assets”)
without selling them. You can deposit them as Collateral and
take out crypto loans in the stable coin $DAI, which is fixed
towards $USD. Upon creating your Digital Asset Loan you can
exchange your $DAI to your local currency and transfer it to
your bank account.`,
              no: `Vanir Digital Assets Loans allows you to borrow against your
crypto assets (known as “Virtual Assets” or “Digital Assets”)
without selling them. You can deposit them as Collateral and
take out crypto loans in the stable coin $DAI, which is fixed
towards $USD. Upon creating your Digital Asset Loan you can
exchange your $DAI to your local currency and transfer it to
your bank account.`,
            },
            line2: {
              en: `Vanir Digital Asset Loans are alway
              nominated in the stablecoin $DAI.`,
              no: `Vanir Digital Asset Loans are alway
              nominated in the stablecoin $DAI.`,
            },
          },
        },
        2: {
          question: {
            en: "What is a stablecoin?",
            no: "What is a stablecoin?",
          },
          answer: {
            line1: {
              en: ` A stablecoin is a type of cryptocurrency where the value of the
              digital asset is supposed to be fixed to a reference asset,
              which is either fiat money, exchange-traded commodities (such as
              precious metals or industrial metals), or another
              cryptocurrency..`,
              no: ` A stablecoin is a type of cryptocurrency where the value of the
              digital asset is supposed to be fixed to a reference asset,
              which is either fiat money, exchange-traded commodities (such as
              precious metals or industrial metals), or another
              cryptocurrency.`,
            },
            line2: {
              en: `Vanir Digital Asset Loans use the stable coin $DAI which is
              fixed towards $USD. $DAI is maintained and regulated by
              MakerDAO, a decentralized autonomous organization (DAO) composed
              of the owners of its governance token, MKR, who may propose and
              vote on changes to certain parameters in its smart contracts in
              order to ensure the stability of $DAI.`,
              no: `Vanir Digital Asset Loans use the stable coin $DAI which is
              fixed towards $USD. $DAI is maintained and regulated by
              MakerDAO, a decentralized autonomous organization (DAO) composed
              of the owners of its governance token, MKR, who may propose and
              vote on changes to certain parameters in its smart contracts in
              order to ensure the stability of $DAI.`,
            },
          },
        },
        3: {
          question: {
            en: "How can I withdraw my loan to my bank account? ",
            no: "How can I withdraw my loan to my bank account? ",
          },
          answer: {
            line1: {
              en: `If you have created a loan;`,
              no: `If you have created a loan;`,
            },
            list1: {
              1: {
                en: "Go to Dashboard → Wallet",
                no: "Go to Dashboard → Wallet",
              },
              2: {
                en: "Select $DAI → Withdraw",
                no: "Select $DAI → Withdraw",
              },
              3: {
                en: "Select → Exchange to NOK",
                no: "Select → Exchange to NOK",
              },
            },
            line2: {
              en: `If you have not created a loan;`,
              no: `If you have not created a loan;`,
            },
            list2: {
              1: {
                en: "Selecting the option “Exchange to NOK” when creating a Digital Asset Loan",
                no: "Selecting the option “Exchange to NOK” when creating a Digital Asset Loan",
              },
            },
          },
        },
        4: {
          question: {
            en: "Which Digital Assets can users borrow on Vanir? ",
            no: "Which Digital Assets can users borrow on Vanir? ",
          },
          answer: {
            line1: {
              en: `You can borrow loans in the Digital Asset $DAI. We will be
              continually adding to this list. Watch this space!`,
              no: `You can borrow loans in the Digital Asset $DAI. We will be
              continually adding to this list. Watch this space!`,
            },
            line2: {
              en: `Select ‘Exchange to NOK’ to exchange
              your $DAI for NOK and recieve them to your bank account. Vanir
              Digital Asset Loans is alway nominated in the stablecoin $DAI.`,
              no: `Select ‘Exchange to NOK’ to exchange
              your $DAI for NOK and recieve them to your bank account. Vanir
              Digital Asset Loans is alway nominated in the stablecoin $DAI.`,
            },
          },
        },
        5: {
          question: {
            en: "Which Digital Assets can users deposit as collateral for on Vanir? ",
            no: "Which Digital Assets can users deposit as collateral for on Vanir? ",
          },
          answer: {
            line1: {
              en: `You can deposit ETH as a Digital Asset collateral. We will be
              continually adding to this list, including BTC`,
              no: `You can deposit ETH as a Digital Asset collateral. We will be
              continually adding to this list, including BTC`,
            },
          },
        },
        6: {
          question: {
            en: `Can I create multiple loans?`,
            no: `Can I create multiple loans??`,
          },
          answer: {
            line1: {
              en: `You can create up to 3 Digital Asset Loans at one time, as
              long as you fulfill the eligibility requirements. Additional
              loans are not allowed if:`,
              no: `You can create up to 3 Digital Asset Loans at one time, as
              long as you fulfill the eligibility requirements. Additional
              loans are not allowed if:`,
            },
            list1: {
              1: {
                en: "You have margin loan(s) currently under Forced Liquidation;",
                no: "You have margin loan(s) currently under Forced Liquidation;",
              },
              2: {
                en: "You do not have sufficient collateral to take out a new loan",
                no: "You do not have sufficient collateral to take out a new loan",
              },
            },
          },
        },
        7: {
          question: {
            en: `How long are the loan terms for Vanir Digital Asset Loans?`,
            no: `How long are the loan terms for Vanir Digital Asset Loans?`,
          },
          answer: {
            line1: {
              en: `All loans on Vanir are up to 12-month in length. You can choose
              to repay at any time with any amount.`,
              no: `All loans on Vanir are up to 12-month in length. You can choose
              to repay at any time with any amount.`,
            },
          },
        },
      },
    },
  },
  loanCalculator: {
    title: { no: "Lånekalkulator", en: "Loan calculator" },
    title2: {
      no: "Opprett lån",
      en: "Open loan",
    },
    months: {
      no: "mnd.",
      en: "mo.",
    },
    initialLtv: {
      no: "Initiell belåningsgrad (LTV)",
      en: "Initial loan-to-value ratio (LTV)",
    },
    ltv: { no: "Belåningsgrad (LTV)", en: "Loan-to-value ratio (LTV)" },
    amount: {
      label: { no: "Lånebeløp", en: "Loan amount" },
      placeholder: {
        no: "Minste lånebeløp ",
        en: "Min. amount ",
      },
      minError: {
        no: "Beløpet er under minimumskravet",
        en: "The amount is under the minimum",
      },
    },
    collateral: {
      label: { no: "Estimert sikkerhet", en: "Estimated collateral" },
      placeholder: {
        no: "Angi størrelse på sikkerhet",
        en: "Write collateral amount",
      },
      colError: {
        no: "Utrygt lån!",
        en: "Dangerous loan!",
      },
      notEnoughError: {
        no: "Ikke nok Eth på kontoen",
        en: "Not enough Eth on account",
      },
    },
    details: {
      title: { no: "Lånedetaljer", en: "Loan details" },
      interest: { no: "Rentesats", en: "Interest" },
      loanAmount: { no: "Lånebeløp", en: "Loan amount" },
      payout: { no: "Utbetalt beløp", en: "Amount paid out" },
      collateral: { no: "Sikkerhet", en: "Collateral" },
      duration: { no: "Lånets løpetid", en: "Loan duration" },
      days: { no: "dager", en: "days" },
    },
    terms: {
      text: {
        no: "Ved oppretting av lån aksepterer du Vanir sine",
        en: "When opening a loan you accept Vanir's",
      },
      link: { no: "lånevilkår", en: "terms of service" },
    },
    toggleConversion: {
      no: "Veksle til NOK",
      en: "Exchange to NOK",
    },
    mainButton: { no: "OPPRETT LÅN", en: "CREATE LOAN" },
  },
  newLoanPage: {
    title: {
      no: "Opprett Lån",
      en: "Open Loan",
    },
    opening: {
      title: {
        no: "Har du ethereum, men ønsker ikke å selge for å få tilgang til likviditet?",
        en: "Do you have etherium, but don't wish to sell to get access to liquid assets?",
      },
      paragraph: {
        no: "Med Vanir kan du bruke dine digitale eiendeler som sikkerhet for å ta opp et lån. Dette betyr at du kan få tilgang til pengene du trenger uten å måtte betale skatt på eventuell gevinst eller selge dine eiendeler til en lavere verdi enn du tror de vil ha i fremtiden.",
        en: "With Vanir, you can use your digital assets as collateral for your loan. This means that you can access the money you need without having to pay taxes on eventual gain or sell your assets for a lower price than what you expect to get in the future.",
      },
    },
    middle: {
      1: {
        title: {
          no: "Opprett lånet",
          en: "Open a loan",
        },
        paragraph: {
          no: "Angi lånebeløp og hvordan du ønsker å få lånet utbetalt. Angi størrelsen på sikkerheten og initiell belåningsgrad. (LTV)",
          en: "Fill in the desired amount and your preferred payout method. Add your collateral and initial loan-to-value ratio. (LTV)",
        },
      },
      2: {
        title: {
          no: "Sikkehet",
          en: "Collateral",
        },
        paragraph: {
          no: "Om du ikke har sikkerhet på konto må du overføre sikkerhet til ditt Ethereum konto hos Vanir.",
          en: "If you lack collateral on your account, you must first transfer it to your account with Vanir.",
        },
      },
      3: {
        title: {
          no: "Opprett lånet",
          en: "Open a loan",
        },
        paragraph: {
          no: "Når du trykker på Opprett lån overføres lånebeløpet til ditt bankkonto eller konto hos Vanir.",
          en: "When you click 'Create loan', the total balance is transferred to your bank account or your account with Vanir.",
        },
      },
    },
  },
  helpPage: {
    title: {
      no: "Hjelp & Support",
      en: "Help & Support",
    },
    FAQ: {
      title: { no: "Vanlig Stilte Spørsmål", en: "FAQ" },
      QA: {
        importantNote: {
          en: "Important Note: ",
          no: "OBS: ",
        },

        1: {
          question: {
            en: "What is a Digital Asset Loan?",
            no: "Hva er et Kryptolån?",
          },
          answer: {
            en: (
              <div>
                <p>
                  Vanir Digital Assets Loans allows you to borrow against your
                  crypto assets (known as “Virtual Assets” or “Digital Assets”)
                  without selling them. You can deposit them as Collateral and
                  take out crypto loans in the stable coin $DAI, which is fixed
                  towards $USD. Upon creating your Digital Asset Loan you can
                  exchange your $DAI to your local currency and transfer it to
                  your bank account.
                </p>
                <br />
                <p>
                  <i>
                    <b>Important Note:</b> Vanir Digital Asset Loans are always
                    nominated in the stablecoin $DAI.
                  </i>
                </p>
              </div>
            ),
            no: (
              <div>
                <p>
                  Vanir Digital Asset Loans gir deg muligheten til å låne penger
                  med kryptovalutaen din som sikkerhet uten å selge dem. Du kan
                  legge dem inn som sikkerhet og ta opp kryptolån i den stabile
                  mynten $DAI, som er knyttet til $USD. Når du oppretter ditt
                  digitale lån, kan du veksle $DAI til lokal valuta og overføre
                  det til bankkontoen din.
                </p>
                <br />
                <p>
                  <i>
                    <b>Viktig merknad:</b> Vanir Digital Asset Loans er alltid
                    nominert i den stabile mynten $DAI.
                  </i>
                </p>
              </div>
            ),
          },
        },
        2: {
          question: {
            en: "How to create a Digital Asset Loan?",
            no: "Hvordan opprette et Kryptolån?",
          },
          answer: {
            en: (
              <div>
                <div className="flex flex-col gap-2">
                  <p>1. Log in to your Vanir account</p>
                  <p>
                    2. Transfer collateral for the loan to your Vanir wallet
                  </p>
                  <p>
                    3. Go to <b>Dashboard</b> {">"} <b>Create Loan</b>
                  </p>
                  <p>
                    4. Set up the loan in the Loan Calculator and choose an
                    Initial LTV loan type and enter the amount you wish to loan
                    and choose Digital Asset Collateral. The system will
                    automatically calculate the collateral amount based on the
                    Initial LTV. You will also see the estimated daily interest,
                    Max LTV, and Loan Maturity time. <br />
                    Choose ‘Exchange to NOK’ if you wish to receive your loan on
                    your bank account.
                  </p>
                  <p>
                    5. Read and agree to the Terms and Conditions. Then, click
                    <b>New Loan</b>.
                  </p>
                </div>
                <br />
                <p>
                  <i>
                    <b>Important Note</b>: Vanir Digital Asset Loans are always
                    nominated in the stablecoin $DAI.
                  </i>
                </p>
              </div>
            ),
            no: (
              <div>
                <div className="flex flex-col gap-2">
                  <p>1. Logg inn på din Vanir-konto</p>
                  <p>2. Overfør sikkerhet for lånet til din Vanir-lommebok</p>
                  <p>
                    3. Gå til <b>Dashboard</b> {">"} <b>Nytt lån</b>
                  </p>
                  <p>
                    4. Sett opp lånet i lånekalkulatoren og velg en innledende
                    LTV lånetype og skriv inn beløpet du ønsker å låne, og velg
                    sikkerhet i krypto. Systemet vil automatisk beregne
                    sikkerhetsbeløpet basert på innledende LTV. Du vil også se
                    estimert daglig rente, maksimum LTV og lånetid. <br />
                    Velg ‘Veksle til NOK’ hvis du ønsker å motta lånet på
                    bankkontoen din.
                  </p>
                  <p>
                    5. Les og godta vilkårene og betingelsene. Klikk deretter på
                    <b>Nytt lån.</b>
                  </p>
                </div>
                <br />
                <p>
                  <i>
                    <b>Viktig merknad</b>: Vanir Digital Asset Loans er alltid
                    nominert i stablecoin $DAI.
                  </i>
                </p>
              </div>
            ),
          },
        },
        3: {
          question: {
            en: "Which Digital Assets can users borrow on Vanir?",
            no: "Hvilke kryptovalutaer kan man låne på Vanir?",
          },
          answer: {
            en: (
              <div>
                <p>
                  You can borrow loans in the Digital Asset $DAI. We will be
                  continually adding to this list. Watch this space!
                </p>
                <br />
                <p>
                  <i>
                    <b>Important Note</b>: Select ‘Exchange to NOK’ to exchange
                    your $DAI for NOK and receive them to your bank account.
                    Vanir Digital Asset Loans are always nominated in the
                    stablecoin $DAI.
                  </i>
                </p>
              </div>
            ),
            no: (
              <div>
                <p>
                  Du kan låne penger i kryptovalutaen $DAI. Vi vil fortsette å
                  utvide denne listen. Følg med her!
                </p>
                <br />
                <p>
                  <i>
                    <b>Viktig merknad</b>: Velg ‘Veksle til NOK’ for å veksle
                    $DAI til NOK og motta pengene på bankkontoen din. Vanir
                    Digital Asset Loans er alltid nominert i stablecoin $DAI.
                  </i>
                </p>
              </div>
            ),
          },
        },
        4: {
          question: {
            en: "Which Digital Assets can users deposit as collateral for on Vanir?",
            no: "Hvilke kryptovalutaer kan man bruke som sikkerhet på Vanir?",
          },
          answer: {
            en: (
              <div>
                <p>
                  You can deposit ETH as a Digital Asset collateral. We will be
                  continually adding to this list, including BTC.
                </p>
              </div>
            ),
            no: (
              <div>
                <p>
                  Du kan sette inn ETH som sikkerhet for kryptovaluta. Vi vil
                  fortsette utvide denne listen, inkludert BTC.
                </p>
              </div>
            ),
          },
        },
        5: {
          question: {
            en: "What is Loan-to-Value (LTV)?",
            no: "Hva er Loan-to-Value (LTV)?",
          },
          answer: {
            en: (
              <div>
                <div className="flex flex-col gap-2">
                  <p>
                    LTV is the ratio of your <u>Loan</u> to the value of your{" "}
                    <u>Collateral</u>.
                  </p>
                  <p>
                    When you borrow a loan, we calculate your loan amount or
                    required Collateral based on the following formula.
                  </p>
                  <p>
                    <b>LTV</b> = <b>(Market Value of Loan Amount)</b> ÷{" "}
                    <b>(Market Value of Collateral)</b>
                  </p>
                  <p>
                    When a loan becomes active, we monitor your loan’s LTV based
                    on the following formula.
                  </p>
                  <p>
                    <b>LTV</b> = <b>(Market Value of Outstanding Balance)</b> ÷{" "}
                    <b>(Market Value of Collateral)</b>
                  </p>
                  <p>
                    {" "}
                    where, <b>Outstanding Balance</b> ={" "}
                    <b>Outstanding Loan Principal</b> +{" "}
                    <b>Outstanding Loan Interest</b>
                  </p>
                  <p>
                    A composite index price is used to calculate the LTV every 5
                    seconds, which incorporates price data from MakerDAO. Based
                    on the price data, we determine the composite index price
                    and use this to value your crypto assets for LTV
                    calculation.
                  </p>
                  <br />
                  <p>
                    <b>Initial LTV</b> → The Initial LTV ratio determines the
                    first amount of coins that can be borrowed.{" "}
                  </p>
                  <p>
                    <b>Safe LTV</b> → As a rule of thumb, a safe loan-to-value
                    ratio should be no greater than than Margin Call 1 LTV.{" "}
                  </p>
                  <p>
                    <b>Margin Call 1 LTV</b> → Early warning 1 → When the LTV
                    ratio is higher than the Margin Call 1 LTV ratio, you’ll
                    receive a notification via email and SMS to remind you to
                    deposit or transfer more collateral to your Loan, or repay
                    the borrowed coins to lower your LTV ratio.
                  </p>
                  <p>
                    <b>Margin Call 2 LTV</b> → Early warning 2 → When the LTV
                    ratio is higher than the Margin Call 2 LTV ratio, you’ll
                    receive a notification via email and SMS to remind you to
                    deposit or transfer more collateral to your Loan, or repay
                    the borrowed coins to lower your LTV ratio.
                  </p>
                  <p>
                    <b>Max LTV</b> → When the LTV ratio is higher than the Max
                    LTV ratio, Vanir will liquidate your collateral assets to
                    repay your loan and interest in full. Please note that
                    liquidation will be triggered when the corresponding Max LTV
                    ratio is reached.
                  </p>
                  <p>
                    <i>Liquidation Fee</i>: In the event of a liquidation, you
                    will be charged 13% of the value of the loaned asset as a
                    liquidation fee and be deducted from your collateral.
                  </p>
                </div>
              </div>
            ),
            no: (
              <div>
                <div className="flex flex-col gap-2">
                  <p>
                    LTV er forholdet mellom <u>lånet</u> ditt og verdien av{" "}
                    <u>sikkerheten</u> på lånet.
                  </p>
                  <p>
                    Når du tar ut et lån, beregner vi lånebeløpet ditt eller
                    nødvendig sikkerhet basert på følgende formel.
                  </p>
                  <p>
                    <b>LTV</b> = <b>(Markedsverdi av lånebeløpet)</b> ÷{" "}
                    <b>(Markedsverdi av sikkerhet)</b>
                  </p>
                  <p>
                    Når et lån blir aktivtert, overvåker vi LTV-en til lånet
                    ditt basert på følgende formel.
                  </p>
                  <p>
                    <b>LTV</b> = <b>(Markedsverdi av utestående saldo)</b> ÷{" "}
                    <b>(Markedsverdi av sikkerhet)</b>
                  </p>
                  <p>
                    {" "}
                    der <b>Utestående saldo</b> = <b>Utestående låneprinsipp</b>{" "}
                    + <b>Utestående lånerente</b>
                  </p>
                  <p>
                    En sammensatt indekspris brukes til å beregne LTV hvert 5.
                    sekund, som inkorporerer prisdata fra MakerDAO. Basert på
                    prisdataene fastsetter vi den sammensatte indeksprisen og
                    bruker denne til å verdsette kryptolånene dine for
                    LTV-beregning.
                  </p>
                  <br />
                  <p>
                    <b>Initial LTV</b> → Den innledende LTV-en bestemmer den
                    første mengden mynter som kan lånes.{" "}
                  </p>
                  <p>
                    <b>Safe LTV</b> → Som en tommelfingerregel bør en trygg LTV
                    ikke være større enn Margin Call 1 LTV.{" "}
                  </p>
                  <p>
                    <b>Margin Call 1 LTV</b> → Tidlig advarsel 1 → Når LTV-en er
                    høyere enn Margin Call 1 LTV, vil du bli varslet via e-post
                    og SMS for å minne deg på å sette inn eller overføre mer
                    sikkerhet til lånet ditt, eller betale tilbake de lånte
                    myntene for å senke LTV-en din.
                  </p>
                  <p>
                    <b>Margin Call 2 LTV</b> → Tidlig advarsel 2 → Når LTV-en er
                    høyere enn Margin Call 2 LTV, vil du bli varslet via e-post
                    og SMS for å minne deg på å sette inn eller overføre mer
                    sikkerhet til lånet ditt, eller betale tilbake de lånte
                    myntene for å senke LTV-en din.
                  </p>
                  <p>
                    <b>Max LTV</b> → Når LTV-en er høyere enn maksimum LTV, vil
                    Vanir likvidere sikkerheten din for å tilbakebetale lånet og
                    rentene i sin helhet. Vær oppmerksom på at likvidasjon vil
                    bli utløst når den korresponderende maksimum LTV-en blir
                    nådd.
                  </p>
                  <p>
                    <i>Likvideringsgebyr</i>: Ved likvidasjon blir det belastet
                    13% av lånebeløpet i gebyr og trukket fra sikkerheten.
                  </p>
                </div>
              </div>
            ),
          },
        },
        6: {
          question: {
            en: "How do I improve my Loan-to-Value (LTV) Percentage??",
            no: "Hvordan kan jeg forbedre prosentandelen av lån-til-verdi (LTV)?",
          },
          answer: {
            en: (
              <div className="flex flex-col gap-2">
                <p>
                  You may adjust your loan’s LTV (loan-to-value) in 3 ways:
                  Adjust Collateral, Increase Loan, Downpay Loan. An active
                  loan’s LTV depends on the outstanding balance of the loan.
                  Please note to keep your LTV in the Safe LTV zone for better
                  Loan Health.{" "}
                </p>
                <p>
                  To <i>decrease</i> your Loan-to-Value (LTV) Percentage;
                </p>
                <p>
                  1. Go to <b>Dashboard</b> → <b>My Loans</b>
                </p>
                <p>
                  2. Select <b>Change Collateral</b> → <b>Deposit Collateral</b>
                </p>
                <p>OR</p>
                <p>
                  1. Go to <b>Dashboard</b> → <b>My Loans</b>
                </p>
                <p>
                  2. Select <b>Increase Loan</b>
                </p>
              </div>
            ),
            no: (
              <div className="flex flex-col gap-2">
                <p>
                  Du kan justere LTV (lån-til-verdi) for lånet ditt på 3 måter:
                  Juster Sikkerhet, Øk Lån, Nedbetal Lån. Et aktivt låns LTV er
                  avhenging av den utestående saldoen til lånet. Vær oppmerksom
                  på å holde LTV-en i Safe LTV-sonen for bedre lånehelse.{" "}
                </p>
                <p>
                  For å <i>redusere</i> prosentandelen av lån-til-verdi (LTV);
                </p>
                <p>
                  1. Gå til <b>Dashboard</b> → <b>Mine lån</b>
                </p>
                <p>
                  2. Velg <b>Endre sikkerhet</b> → <b>Sett inn sikkerhet</b>
                </p>
                <p>ELLER</p>
                <p>
                  1. Gå til <b>Dashboard</b> → <b>Mine lån</b>
                </p>
                <p>
                  2. Velg <b>Øk lån</b>
                </p>
              </div>
            ),
          },
        },
        7: {
          question: {
            en: "What should I know about my Loan Health?",
            no: "Hva bør jeg vite om lånshelsen min?",
          },
          answer: {
            en: (
              <div className="flex flex-col gap-2">
                <p>
                  Each of your outstanding loans will have a Loan Heath
                  indicator, which reflects the risk level based on the
                  outstanding loan balance and your deposited Collateral (LTV).
                  You should monitor your loans and make repayments accordingly
                  to improve your Loan Health.
                </p>
                <br />
                <Image
                  width={936}
                  height={167}
                  src={"/images/loanComponent.png"}
                  alt="Example of an active loan"
                />
                <p>
                  <b>Note:</b> The figures in the above screenshot are for
                  illustrative purposes only.
                </p>
                <br />
                <p>
                  A health score, which is based on your loan’s LTV and the
                  defined thresholds, is provided to help you better manage your
                  loan.
                </p>
                <table className="table-fixed border-collapse border border-black">
                  <tr>
                    <th className="border border-black">Loan's LTV</th>
                    <th className="border border-black">Health Score</th>
                  </tr>
                  <tr>
                    <td className="border border-black  pl-2">Safe LTV</td>
                    <td className="border border-black  pl-2">GOOD</td>
                  </tr>
                  <tr>
                    <td className="border border-black pl-2">
                      Margin Call LTV
                    </td>
                    <td className="border border-black pl-2">
                      FAIR/POOR/CRITICAL
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black pl-2">Max LTV</td>
                    <td className="border border-black pl-2">LIQUIDATED</td>
                  </tr>
                </table>
                <br />
                <p>LTV thresholds vary per Loan Type. </p>
              </div>
            ),
            no: (
              <div className="flex flex-col gap-2">
                <p>
                  Hvert av de utestående lånene dine vil ha en
                  lånehelseindikator, som gjenspeiler risikonivået basert på den
                  utestående saldoen til lånet og din innskutte sikkerhet (LTV).
                  Du bør overvåke lånene dine og gjøre tilbakebetalinger
                  deretter for å forbedre lånehelsen din.
                </p>
                <br />
                <Image
                  width={936}
                  height={167}
                  src={"/images/loanComponent.png"}
                  alt="Eksempel på et aktivt lån"
                />
                <p>
                  <b>Merk:</b> Figurene i skjermbildet ovenfor er kun for
                  illustrasjon.
                </p>
                <br />
                <p>
                  En helsescore, som er basert på lånets LTV og de definerte
                  tersklene, er gitt for å hjelpe deg å administrere lånet ditt
                  bedre.
                </p>
                <table className="table-fixed border-collapse border border-black">
                  <tr>
                    <th className="border border-black">Lånets LTV</th>
                    <th className="border border-black">Helsescore</th>
                  </tr>
                  <tr>
                    <td className="border border-black  pl-2">Safe LTV</td>
                    <td className="border border-black  pl-2">GOOD</td>
                  </tr>
                  <tr>
                    <td className="border border-black pl-2">
                      Margin Call LTV
                    </td>
                    <td className="border border-black pl-2">
                      FAIR/POOR/CRITICAL
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black pl-2">Max LTV</td>
                    <td className="border border-black pl-2">LIQUIDATED</td>
                  </tr>
                </table>
                <br />
                <p>LTV-tersklene varierer per lånetype. </p>
              </div>
            ),
          },
        },
        8: {
          question: {
            en: "How is the interest calculated?",
            no: "Hvordan beregnes renten?",
          },
          answer: {
            en: (
              <div className="flex flex-col gap-2">
                <p>
                  Interest rates are split into ‘Blockchain fee’ and ‘Vanir fee’
                  and is charged to your loan.{" "}
                </p>
                <p>
                  <b>Blockchain fee</b> accrues <i>daily</i> based on your
                  unpaid loan principal and is compounded on earlier interest
                  charges.
                </p>
                <p>
                  <b>Vanir fee</b> accrues <i>weekly</i> based on your unpaid
                  loan principal and is compounded on earlier interest charges.
                </p>
                <br />
                <p>The interest rates for standard loans are as follows:</p>
                <table className="table-fixed border-collapse border border-black">
                  <tr>
                    <th className="border border-black">Loan Type</th>
                    <th className="border border-black">Initial LTV</th>
                    <th className="border border-black">Interest rate</th>
                  </tr>
                  <tr>
                    <td className="border border-black  pl-2">Low</td>
                    <td className="border border-black pl-2">LTV = 35%</td>
                    <td className="border border-black pl-2">
                      9.50% APR (5.00% Blockchain fee + 4.50% Vanir fee)
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black  pl-2">Mid</td>
                    <td className="border border-black pl-2">LTV = 45%</td>
                    <td className="border border-black pl-2">
                      9.00% APR (5.25% Blockchain fee + 3.75% Vanir fee)
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black  pl-2">High</td>
                    <td className="border border-black  pl-2">LTV = 55%</td>
                    <td className="border border-black  pl-2">
                      8.50% APR (5.75% Blockchain fee + 2.75% Vanir fee)
                    </td>
                  </tr>
                </table>
                <br />
                <p>
                  <i>
                    <b>Important Note:</b> Interest rates are subject to change
                    at any time.
                  </i>
                </p>
              </div>
            ),
            no: (
              <div className="flex flex-col gap-2">
                <p>
                  Rentesatsene er delt inn i ‘Blockchain-gebyr’ og ‘Vanir-gebyr’
                  og belastes lånet ditt.{" "}
                </p>
                <p>
                  <b>Blockchain-gebyr</b> akkumuleres <i>daglig</i> basert på
                  det ubetalte låneprinsippet ditt og blir sammensatt på
                  tidligere rentekostnader.
                </p>
                <p>
                  <b>Vanir-gebyr</b> akkumuleres <i>ukentlig</i> basert på det
                  ubetalte låneprinsippet ditt og blir sammensatt på tidligere
                  rentekostnader.
                </p>
                <br />
                <p>Rentesatsene for standardlån er som følger:</p>
                <table className="table-fixed border-collapse border border-black">
                  <tr>
                    <th className="border border-black">Lånetype</th>
                    <th className="border border-black">Innledende LTV</th>
                    <th className="border border-black">Rentesats</th>
                  </tr>
                  <tr>
                    <td className="border border-black  pl-2">Lav</td>
                    <td className="border border-black pl-2">LTV = 35%</td>
                    <td className="border border-black pl-2">
                      9.50% APR (5.00% Blockchain-gebyr + 4.50% Vanir-gebyr)
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black  pl-2">Med.</td>
                    <td className="border border-black pl-2">LTV = 45%</td>
                    <td className="border border-black pl-2">
                      9.00% APR (5.25% Blockchain-gebyr + 3.75% Vanir-gebyr)
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black  pl-2">Høy</td>
                    <td className="border border-black  pl-2">LTV = 55%</td>
                    <td className="border border-black  pl-2">
                      8.50% APR (5.75% Blockchain-gebyr + 2.75% Vanir-gebyr)
                    </td>
                  </tr>
                </table>
                <br />
                <p>
                  <i>
                    <b>Viktig merknad:</b> Rentesatsene kan endres når som
                    helst.
                  </i>
                </p>
              </div>
            ),
          },
        },
        9: {
          question: {
            en: "How does repayment work?",
            no: "Hvordan fungerer tilbakebetaling?",
          },
          answer: {
            en: (
              <div className="flex flex-col gap-2">
                <p>
                  You can repay loans from your Vanir Wallet. Repayments of any
                  amount can be made at any time during the loan term. Your
                  repayments of the outstanding loan principal and interest can
                  only be made in the borrowed crypto asset, $DAI.{" "}
                </p>
                <p>
                  Your repayment will be used to first repay all the outstanding
                  interest followed by the outstanding loan principal.
                </p>
                <br />
                <p>
                  1. Go to <b>Dashboard</b> → <b>My Loans</b>
                </p>
                <p>
                  2. Select <b>Pay Loan</b> → <b>Insert Amount</b>
                </p>
              </div>
            ),
            no: (
              <div className="flex flex-col gap-2">
                <p>
                  Du kan tilbakebetale lån fra Vanir-lommeboken din.
                  Tilbakebetalinger av hvilket som helst beløp kan gjøres når
                  som helst i løpet av låneperioden. Tilbakebetalingene av det
                  utestående lånebeløpet og renten kan bare gjøres i den lånte
                  kryptovalutaen, $DAI.{" "}
                </p>
                <p>
                  Betalingen din vil bli brukt til å først betale all utestående
                  rente etterfulgt av det utestående lånebeløpet.
                </p>
                <br />
                <p>
                  1. Gå til <b>Dashboard</b> → <b>Mine lån</b>
                </p>
                <p>
                  2. Velg <b>Betal lån</b> → <b>Skriv inn beløp</b>
                </p>
              </div>
            ),
          },
        },
        10: {
          question: {
            en: "What is a stablecoin?",
            no: "Hva er en stablecoin?",
          },
          answer: {
            en: (
              <div className="flex flex-col gap-2">
                <p>
                  A stablecoin is a type of cryptocurrency where the value of
                  the digital asset is supposed to be fixed to a reference
                  asset, which is either fiat money, exchange-traded commodities
                  (such as precious metals or industrial metals), or another
                  cryptocurrency.
                </p>
                <p>
                  Vanir Digital Asset Loans use the stable coin $DAI which is
                  fixed towards $USD. $DAI is maintained and regulated by
                  MakerDAO, a decentralized autonomous organization (DAO)
                  composed of the owners of its governance token, MKR, who may
                  propose and vote on changes to certain parameters in its smart
                  contracts to ensure the stability of $DAI.{" "}
                </p>
              </div>
            ),
            no: (
              <div className="flex flex-col gap-2">
                <p>
                  En stablecoin er en type kryptovaluta der verdien av den
                  digitale mynten skal være bundet til en referanseverdi, som
                  enten er fiat-penger, børshandlede varer (slik som
                  edelmetaller eller industrielle metaller), eller en annen
                  kryptovaluta.
                </p>
                <p>
                  Vanir Digital Asset Loans bruker den stabile mynten $DAI som
                  er bundet til $USD. $DAI vedlikeholdes og reguleres av
                  MakerDAO, en desentralisert autonom organisasjon (DAO)
                  sammensatt av eierne av styringstokenen, MKR, som kan foreslå
                  og stemme over endringer i visse parametere i smartkontraktene
                  for å sikre stabiliteten til $DAI.{" "}
                </p>
              </div>
            ),
          },
        },
        11: {
          question: {
            en: "Can I create multiple loans?",
            no: "Kan jeg opprette flere lån?",
          },
          answer: {
            en: (
              <div className="flex flex-col gap-2">
                <p>
                  You can create up to 3 Digital Asset Loans at one time, as
                  long as you fulfill the eligibility requirements. Additional
                  loans are not allowed if:
                </p>
                <ul className="list-inside list-disc">
                  <li>
                    You have margin loan(s) currently under Forced Liquidation;
                  </li>
                  <li>
                    You do not have sufficient collateral to take out a new loan
                  </li>
                </ul>
              </div>
            ),
            no: (
              <div className="flex flex-col gap-2">
                <p>
                  Du kan opprette opptil 3 digitale eiendomslån samtidig, så
                  lenge du oppfyller kvalifikasjonskravene. Ytterligere lån er
                  ikke tillatt hvis:
                </p>
                <ul className="list-inside list-disc">
                  <li>
                    Du har marginlån som for øyeblikket er under tvungen
                    likvidasjon;
                  </li>
                  <li>
                    Du har ikke tilstrekkelig sikkerhet for å ta opp et nytt lån
                  </li>
                </ul>
              </div>
            ),
          },
        },
        12: {
          question: {
            en: "Can I withdraw Digital Assets borrowed under from my loan?",
            no: "Kan jeg ta ut kryptovalutaen som er lånt under lånet mitt?",
          },
          answer: {
            en: (
              <div className="flex flex-col gap-2">
                <p>
                  You can withdraw Digital Assets borrowed from your Vanir
                  Wallet to your whitelisted address.
                </p>
              </div>
            ),
            no: (
              <div className="flex flex-col gap-2">
                <p>
                  Du kan ta ut kryptovalutaen som er lånt fra Vanir-lommeboken
                  din til den hvitelistede adressen din.
                </p>
              </div>
            ),
          },
        },
        13: {
          question: {
            en: "How can I withdraw my loan to my bank account? ",
            no: "Hvordan kan jeg overføre lånet mitt til bankkontoen min?",
          },
          answer: {
            en: (
              <div className="flex flex-col gap-2">
                <p>If you have created a loan;</p>
                <p>1. Go to Dashboard → Wallet</p>
                <p>2. Select $DAI → Withdraw</p>
                <p>3. Select → Exchange to NOK</p>
                <p>If you have not created a loan;</p>
                <p>
                  1. Selecting the option “Exchange to NOK” when creating a
                  Digital Asset Loan
                </p>
              </div>
            ),
            no: (
              <div className="flex flex-col gap-2">
                <p>Hvis du har opprettet et lån;</p>
                <p>1. Gå til Dashboard → Lommebok</p>
                <p>2. Velg $DAI → Ta ut</p>
                <p>3. Velg → Veksle til NOK</p>
                <p>Hvis du ikke har opprettet et lån;</p>
                <p>
                  1. Velge alternativet "Veksle til NOK" når du oppretter et
                  Digital Asset Loan
                </p>
              </div>
            ),
          },
        },
        14: {
          question: {
            en: "How long are the loan terms for Vanir Digital Asset Loans?",
            no: "Hvor lenge varer lånebetingelsene for Vanir Digital Asset Loans?",
          },
          answer: {
            en: (
              <div className="flex flex-col gap-2">
                <p>
                  All loans on Vanir are up to 12-month in length. You can
                  choose to repay at any time with any amount.{" "}
                </p>
              </div>
            ),
            no: (
              <div className="flex flex-col gap-2">
                <p>
                  Alle lån på Vanir varer opptil 12 måneder. Du kan velge å
                  betale når som helst med hvilket som helst beløp.{" "}
                </p>
              </div>
            ),
          },
        },
        15: {
          question: {
            en: "Vanir Digital Asset Loan Terminology",
            no: "Terminologi for Vanir Digital Asset Loan",
          },
          answer: {
            en: (
              <div className="flex flex-col gap-2">
                <table className="table-fixed border-collapse border border-black">
                  <tr>
                    <th className="border border-black">Term</th>
                    <th className="border border-black">Description</th>
                  </tr>
                  <tr>
                    <td className="border border-black  pl-2">Collateral</td>
                    <td className="border border-black pl-2">
                      The amount of immediately available Digital Assets
                      maintained, or required to be maintained in Vanir Lending
                      for loan drawdown.
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td className="border border-black  pl-2">Wallet</td>
                    <td className="border border-black pl-2">
                      The primary crypto wallet for your Vanir Account, which
                      you can use to receive loans, deposit Collateral and make
                      loan repayments.
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td className="border border-black  pl-2">
                      Loan-to-Value (LTV)
                    </td>
                    <td className="border border-black pl-2">
                      LTV is the ratio of your loan to the value of your
                      Collateral. When you borrow a loan, we calculate your loan
                      amount or required Collateral based on the following
                      formula:
                      <br />
                      LTV = (Loan Amount) ÷ (Market Value of Collateral)
                      <br />
                      When a loan becomes active, we monitor your loan's LTV
                      based on the following formula:
                      <br />
                      LTV = (Market Value of Outstanding Balance) ÷ (Market
                      Value of Collateral)
                      <br />
                      See "What is Loan-to-Value (LTV)?" for further details.
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td className="border border-black  pl-2">
                      Loan Maturity Time
                    </td>
                    <td className="border border-black pl-2">
                      The maximum amount of time we can provide you with credit
                      under a loan. <br />
                      <br />
                      Vanir Digital Asset Loan terms are 12 months in length,
                      with hourly interest calculation. So the loan term period
                      will be 364 days 23 hours {"<"} loan term {"<="} 365 days.{" "}
                      <br />
                      You can choose to repay at any time with any amount during
                      the loan term.
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td className="border border-black  pl-2">
                      Minimum Borrowing Limit
                    </td>
                    <td className="border border-black pl-2">
                      {" "}
                      The minimum amount of credit that Vanir Digital Asset
                      Loans can provide, is based on your LTV and lending
                      package. Minimum is 3500 $DAI.
                      <br />
                      See "What are the loan limits?" for further details.
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td className="border border-black  pl-2">Margin Call</td>
                    <td className="border border-black pl-2">
                      A Margin Call will be triggered when your loan's LTV
                      reaches or exceeds the prescribed threshold, and you will
                      receive email notifications regarding loan repayment.
                      <br />
                      See "What happens if my loan is on Margin Call?" for
                      further details.
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td className="border border-black  pl-2">
                      Forced Liquidation
                    </td>
                    <td className="border border-black pl-2">
                      When your loan's LTV reaches or exceeds the prescribed
                      threshold set by Vanir for a Forced Liquidation, we will
                      liquidate the entire corresponding Collateral to make full
                      repayment of your loan. After the Forced Liquidation, all
                      remaining liquidation proceeds will be deposited into your
                      Crypto.com Exchange Wallet. Please note that a Liquidation
                      Fee will be applied.
                      <br />
                      See "What triggers Forced Liquidation?" for further
                      details.
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td className="border border-black  pl-2">Interest</td>
                    <td className="border border-black pl-2">
                      The cost of borrowing a crypto loan.
                      <br />
                      See "How is interest calculated?" for further details.
                    </td>
                  </tr>
                </table>
              </div>
            ),
            no: (
              <div className="flex flex-col gap-2">
                <table className="table-fixed border-collapse border border-black">
                  <tr>
                    <th className="border border-black">Begrep</th>
                    <th className="border border-black">Beskrivelse</th>
                  </tr>
                  <tr>
                    <td className="border border-black  pl-2">Sikkerhet</td>
                    <td className="border border-black pl-2">
                      Beløpet av umiddelbart tilgjengelige digitale eiendeler
                      som opprettholdes, eller som kreves å opprettholdes i
                      Vanir Lending for lån.
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td className="border border-black  pl-2">Lommebok</td>
                    <td className="border border-black pl-2">
                      Hovedkrypto-lommeboken for din Vanir-konto, som du kan
                      bruke til å motta lån, sette inn sikkerhet og foreta
                      tilbakebetaling av lån.
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td className="border border-black  pl-2">
                      Loan-to-Value (LTV)
                    </td>
                    <td className="border border-black pl-2">
                      LTV er forholdet mellom lånet ditt og verdien av
                      sikkerheten din. Når du tar ut et lån, beregner vi
                      lånebeløpet ditt eller påkrevd sikkerhet basert på
                      følgende formel:
                      <br />
                      LTV = (Lånebeløp) ÷ (Markedsverdi av sikkerheten)
                      <br />
                      Når et lån blir aktivert, overvåker vi LTV for lånet ditt
                      basert på følgende formel:
                      <br />
                      LTV = (Markedsverdi av utestående saldo) ÷ (Markedsverdi
                      av sikkerheten)
                      <br />
                      Se "Hva er Loan-to-Value (LTV)?" for ytterligere detaljer.
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td className="border border-black  pl-2">
                      Lånetidens utløp
                    </td>
                    <td className="border border-black pl-2">
                      Maksimal tid vi kan gi deg kreditt for et lån. <br />
                      <br />
                      Vanir Digital Asset Loan-betingelsene varer i 12 måneder,
                      med beregning av renter hver time. Så låneperioden vil
                      være 364 dager 23 timer {"<"} låneperiode {"<="} 365
                      dager. <br />
                      Du kan velge å betale når som helst med hvilket som helst
                      beløp i løpet av låneperioden.
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td className="border border-black  pl-2">
                      Minimum lånegrense
                    </td>
                    <td className="border border-black pl-2">
                      {" "}
                      Det minste kredittbeløpet som Vanir Digital Asset Loans
                      kan tilby, er basert på LTV og låneavtalen din. Minimum er
                      3500 $DAI.
                      <br />
                      Se "Hva er lånegrensene?" for ytterligere detaljer.
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td className="border border-black  pl-2">Margin Call</td>
                    <td className="border border-black pl-2">
                      En Margin Call utløses når LTV for lånet ditt når eller
                      overstiger den foreskrevne terskelen, og du vil motta
                      e-postvarsler angående tilbakebetaling av lån.
                      <br />
                      Se "Hva skjer hvis lånet mitt er på Margin Call?" for
                      ytterligere detaljer.
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td className="border border-black  pl-2">
                      Tvungen likvidasjon
                    </td>
                    <td className="border border-black pl-2">
                      Når LTV for lånet ditt når eller overstiger den
                      foreskrevne terskelen satt av Vanir for en tvungen
                      likvidasjon, vil vi likvidere hele tilsvarende sikkerheten
                      for å gjøre full tilbakebetaling av lånet ditt. Etter
                      tvungen likvidasjon vil alle gjenværende
                      likvideringsinntekter bli satt inn på din Crypto.com
                      Exchange-lommebok. Vær oppmerksom på at det vil bli
                      belastet et likvidasjonsgebyr.
                      <br />
                      Se "Hva utløser tvungen likvidasjon?" for ytterligere
                      detaljer.
                    </td>
                  </tr>
                  <tr>
                    {" "}
                    <td className="border border-black  pl-2">Rente</td>
                    <td className="border border-black pl-2">
                      Kostnaden ved å låne et kryptolån.
                      <br />
                      Se "Hvordan beregnes renten?" for ytterligere detaljer.
                    </td>
                  </tr>
                </table>
              </div>
            ),
          },
        },
        16: {
          question: {
            en: "How does Vanir Digital Assets Loans work?",
            no: "Hvordan fungerer Vanir Digital Asset Loans?",
          },
          answer: {
            en: (
              <div className="flex flex-col gap-2">
                <p>1. Deposit your Digital Assets to your Vanir Wallet.</p>
                <p>2. Create a Digital Asset Loan in Vanir.</p>
                <p>
                  3. Vanir deposits your Digital Assets into a smart contract
                  with a Blockchain Bank (MakerDAO) and creates a Collateralized
                  Debt Position (CDP). This CDP is how $DAI is created: by
                  locking collateral into the Blockchain Bank smart contract to
                  produce $DAI, the stablecoin.
                </p>
                <p>4. The $DAI is sent back to your Vanir Wallet.</p>
                <p>
                  5. If you choose to exchange your $DAI to your local currency
                  upon creating a loan, our third-party FX-Exchange partner K33
                  will exchange your $DAI to your local currency and send it to
                  your listed bank account.
                </p>
                <p>6. Reference chart</p>
                <Image
                  width={672}
                  height={658}
                  src="/images/loanCycle.png"
                  alt="A chart of Vanir's service flow"
                />
                <br />
                <br />
                <p>
                  Upon creating your loan, you can opt-in to exchange your
                  borrowed $DAI stable coins
                </p>
                <p>
                  To fulfill your financial needs, use them for Margin Trading
                  on the{" "}
                  <u>
                    <a href="http://crypto.com/">Crypto.com</a>
                  </u>{" "}
                  exchange, or hedge on other exchange platforms. A stablecoin
                  is a type of cryptocurrency where the value of the digital
                  asset is supposed to be fixed to a reference asset, which is
                  either fiat money, exchange-traded commodities (such as
                  precious metals or industrial metals), or another
                  cryptocurrency.
                </p>
                <p>
                  Vanir Digital Asset Loans use the stable coin $DAI which is
                  fixed towards $USD. $DAI is maintained and regulated by
                  MakerDAO, a decentralized autonomous organization (DAO)
                  composed of the owners of its governance token, MKR, who may
                  propose and vote on changes to certain parameters in its smart
                  contracts in order to ensure the stability of $DAI.
                </p>
              </div>
            ),
            no: (
              <div className="flex flex-col gap-2">
                <p>1. Sett inn kryptovalutaene dine på din Vanir-lommebok.</p>
                <p>2. Opprett et lån for kryptovaluta i Vanir.</p>
                <p>
                  3. Vanir setter inn kryptovaluta i en smartkontrakt med en
                  Blockchain Bank (MakerDAO) og oppretter en Collateralized Debt
                  Position (CDP). Denne CDP-en er hvordan $DAI opprettes: ved å
                  låse sikkerhet i Blockchain Bank-smartkontrakten for å
                  produsere $DAI, den stabile mynten.
                </p>
                <p>4. $DAI sendes tilbake til Vanir-lommeboken din.</p>
                <p>
                  5. Hvis du velger å veksle $DAI til lokal valuta ved
                  opprettelse av et lån, vil vår tredjeparts FX-Exchange-partner
                  K33 veksle $DAI til lokal valuta og sende det til den oppgitte
                  bankkontoen din.
                </p>
                <p>6. Referansediagram</p>
                <Image
                  width={672}
                  height={658}
                  src="/images/loanCycle.png"
                  alt="Et diagram over Vanirs tjenesteflyt"
                />
                <br />
                <br />
                <p>
                  Ved opprettelsen av lånet kan du velge å veksle de lånte
                  $DAI-myntene dine
                </p>
                <p>
                  For å imøtekomme dine økonomiske behov, bruk dem til Margin
                  Trading på{" "}
                  <u>
                    <a href="http://crypto.com/">Crypto.com</a>
                  </u>{" "}
                  børsen, eller hedge på andre børsplattformer. En stabilmynt er
                  en type kryptovaluta der verdien av valutaen skal være bundet
                  til en referanseverdi, enten fiat-penger, børshandlede varer
                  (slik som edelmetaller eller industrielle metaller), eller en
                  annen kryptovaluta.
                </p>
                <p>
                  Vanir Digital Asset Loans bruker den stabile mynten $DAI, som
                  er bundet til $USD. $DAI vedlikeholdes og reguleres av
                  MakerDAO, en desentralisert autonom organisasjon (DAO)
                  bestående av eierne av styringstokenen, MKR, som kan foreslå
                  og stemme over endringer i visse parametere i sine
                  smartkontrakter for å sikre stabiliteten til $DAI.
                </p>
              </div>
            ),
          },
        },
      },
    },
  },
  toolTips: {
    rate: {
      title: { no: "Rente på lån", en: "Interest on loan" },
      text: {
        no: "Produktets rente baserer seg på en Blockchain fee og Vanir fee",
        en: "Based on a Blockchain fee and a Vanir fee",
      },
    },
    duration: {
      title: { no: "Løpetid på lån", en: "Loan duration" },
      text: {
        no: "Ved utløpet av låneperioden skjer automatisk full tilbakebetaling av lånet om ikke annet er avtalt med Vanir.",
        en: "At the end of the period, unless otherwise agreed to by Vanir, a full payment of the loan will occur.",
      },
    },
    total: {
      title: { no: "Totalt lån", en: "Total loan" },
      text: {
        no: "Ditt totale lån, inkludert akkumulerte renter, er i DAI, nominert i NOK",
        en: "Your total loan, including accumulated interest, is in Dai, denominated in NOK",
      },
    },
    collateral: {
      title: { no: "Sikkerhet", en: "Collateral" },
      text: {
        no: "Din sikkerhet i ETH nominert i NOK",
        en: "You collateral in ETH denominated in NOK",
      },
    },
    ltv: {
      title: { no: "Lånegrad", en: "Loan-to-value ratio" },
      text: {
        no: "Loan To Value (LTV) viser hvor godt sikret ditt lån er. Ved negative svingninger i markedet vil Vanir oppdatere deg om lånets helse via epost og SMS",
        en: "Loan To Value (LTV) shows how well secured a loan is. Vanir will update you on your loan's health via SMS or Email during negative market trends",
      },
    },
  },
  waitingListPage: {
    inviteCodeError: {
      used: {
        en: "Code already in use",
        no: "Kode allerede i bruk",
      },
      invalid: {
        en: "Not a valid invite code",
        no: "Ugyldig kode",
      },
    },
    title1: {
      en: "Access By",
      no: "Tilgang Kun",
      break: {
        en: " Invites Only",
        no: " Med Invitasjon",
      },
    },
    opening1: {
      en: "The invite code is your ticket to accessing Vanir ",
      no: "Invitasjonskoden er din billett for å få tilgang ",
      break: {
        en: "until we open up for new customers.",
        no: "til Vanir til vi åpner for nye kunder.",
      },
    },
    paragraph1: {
      en: "Our commitment to delivering exceptional quality services is unwavering, and to ensure that our service quality remains at its highest, we are carefully managing our new customer inflows.",
      no: "Vår forpliktelse til å levere tjenester av eksepsjonell kvalitet er urokkelig, og for å sikre at tjenestekvaliteten vår forblir på sitt høyeste, styrer vi vår nye kundetilførsel nøye.",
    },
    label1: {
      en: "Invite code",
      no: "Invitasjonskode",
    },
    button1: {
      en: "Check code",
      no: "Sjekk kode",
    },
    title2: {
      en: "Secure Your",
      no: "Sikre din",
      break: {
        en: " Invitation Code",
        no: " invitasjonskode",
      },
    },
    opening2: {
      en: "Left behind without an invite code?",
      no: "Satt igjen uten invitasjonskode?",
    },
    paragraph2: {
      1: {
        en: "Sign up now to ensure you're at the front of the line of the waitlist. \nWe operate on a first-come, first-served basis for new customer intake.",
        no: "Registrer deg nå for å sikre at du står foran på køen på ventelisten. \nVi opererer etter førstemann til mølla-prinsippet for inntak av nye kunder.",
      },
      2: {
        en: "Your registration puts you in a prime position to gain access as soon as we expand our capacity.",
        no: "Registreringen din setter deg i en utmerket posisjon for å få tilgang så snart vi utvider kapasiteten vår.",
      },
      3: {
        en: "Leave your email address below and you’ll be among the first to know when we start accepting new customers.",
        no: "Legg igjen e-postadressen din nedenfor, og du vil være blant de første som får vite når vi begynner å ta imot nye kunder.",
      },
    },
    label2: {
      en: "Your Email Address",
      no: "Din Epostadresse",
    },
    button2: {
      en: "Send me an invitation",
      no: "Send meg en invitasjon",
    },
    signInButton: {
      en: "Sign in",
      no: "Logg inn",
    },
  },
  terms: {
    no: "Bruksvilkår",
    en: "Terms of service",
  },
} satisfies LocalizedText;
